.ui-table {
  margin-top: 16px;
  width: 100%;

  &-avatar {
    img {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }
  }
}
