.visible-wrapper {
  padding: 16px;
  background-color: #fff;
  border-radius: 5px;

  h5 {
    padding: 0;
    margin: 0;
  }
}

.delete-collection-button {
  width: 100%;
}
