.modal-create {
  &__birthday {
    .ant-picker.ant-picker-large {
      width: 100%;
    }
  }
}

.qualification-block {
  .ant-space-item:nth-child(2) {
    flex-grow: 1;
  }
}

.user-edit-tab {
  label {
    white-space: pre-line;
  }
}

.content-listing-en {
  background-color: rgb(243, 221, 210);
}

.content-listing-ru {
  background-color: rgb(196, 218, 211);
}

.lang-tab .ant-tabs-nav-list {
  .ant-tabs-tab {
    height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;
  }

  /* :nth-child(1) {
    @extend .content-listing-en;
    div {
      background-color: transparent;
    }
  }
  :nth-child(2) {
    @extend .content-listing-ru;
    div {
      background-color: transparent;
    }
  } */
}
