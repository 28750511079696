.user-review {
  &__item {
    background-color: #fff;
    border: 1px solid #d9d9d9;
  }
  &__list {
    .ant-list-empty-text {
      background-color: #fff;
    }
  }
}
