@import '../../../assets/scss/mixins';

.user-card {
  &.ant-page-header {
    background-color: transparent;
  }
  &__button-more {
    @include blue-button;
  }
  &__delete-button {
    width: 100%;
  }
}
