.expert {
  &-container {
    padding-bottom: 0 !important;
  }
  &-list {
    margin: 0 -16px !important;
  }

  &-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
    padding: 16px;

    &:last-child {
      border: 0;
    }
  }

  &-call {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    color: #1890ff;
  }
}
