.psychologist-section-card-calendar-wrapper {
  padding: 16px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

.availability-calendar {
  &__day {
    width: 40px;
    height: 40px;
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
    &--available {
      background-color: #f13a3a;
      color: #fff;
    }
  }
  &__header {
    margin-bottom: 12px;
  }
  &.ant-picker-calendar-mini .ant-picker-content th {
    padding-right: 10px;
  }
}

@media screen and (max-width: 1366px) {
  .psychologist-section-card-calendar-wrapper {
    padding: 10px;
  }

  .availability-calendar {
    &__day {
      width: 25px;
      height: 25px;
    }
    &.ant-picker-calendar-mini .ant-picker-content th {
      padding-right: 8px;
      width: 25px;
    }
  }
}
