.creation-wrapper {
  .creation-generate {
    text-align: right;
    button {
      padding: 0;
    }
  }
  h5 {
    padding-top: 24px;
    padding-bottom: 16px;
  }
  .discount-value {
    margin-top: 16px;
  }
  .ant-form-item {
    margin: 0;
  }
}
