.discount-wrapper {
  > div {
    max-width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.discount-add__delete {
  width: 100%;
}

.discount-box {
  .ant-input-number-group-wrapper, .ant-input-number {
    width: 100%;
  }
}
