.limited-customer-wrapper {
  .limited-customer-search {
    width: 100%;
    margin-top: 16px;
  }

  .limited-customer-select {
    margin-top: 17px;
    margin-bottom: 8px;
  }

  .limited-customer-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-top: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
  }
  .ant-select {
    width: 100%;
  }
  .ant-form-item {
    margin: 0;
  }
  .limited-select {
    .ant-select-selection-item {
      display: none;
    }
  }
  .limited-tag {
    margin-bottom: 10px;
  }
}
