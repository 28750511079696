.people-search__wrap {
  width: 100%;
}

.user-select-list {
  &__item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 20px;
    &:hover {
      background: #fafafa;
    }
  }

  &__validation {
    margin-top: -20px;
  }

}

.transaction-create__amount {
  margin: 0 5px;
}
