.site-header {
  background: #ffffff !important;
  padding: 0 16px !important;

  .logo,
  .search,
  .user {
    display: flex;
    align-items: center;
  }
  .logo,
  .search {
    justify-content: flex-start;
  }
  .logo {
    &__name {
      font-weight: 700;
      font-size: 14px;
      margin-left: 10px;
    }
  }
  .search {
    &-input {
      width: 500px;
    }
  }

  .user {
    justify-content: flex-end;
  }

  .lang {
    &__container {
      margin-left: 20px;
    }
  }
}
