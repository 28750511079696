.user-action-wrapper {
  .actions-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 16px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    min-height: 200px;
  }

  .ant-timeline.ant-timeline-label .ant-timeline-item-label {
    text-align: left;
  }

  .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-right .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-label .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head,
  .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
    left: 13%;
  }

  .ant-timeline.ant-timeline-alternate
    .ant-timeline-item-left
    .ant-timeline-item-content,
  .ant-timeline.ant-timeline-right
    .ant-timeline-item-left
    .ant-timeline-item-content,
  .ant-timeline.ant-timeline-label
    .ant-timeline-item-left
    .ant-timeline-item-content {
    left: calc(13% - 4px);
  }

  .ant-timeline-item-label {
    color: #8c8c8c;
  }

  .ant-timeline-item-last {
    padding: 0;
    .ant-timeline-item-content {
      min-height: auto !important;
    }
  }
}
