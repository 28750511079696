@import './assets/scss/style.scss';
@import './assets/scss/validate.scss';
@import './assets/scss/ant.scss';
@import './assets/scss/mixins';

* {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.ant-page-header-back {
  width: 40px;
  height: 40px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  &-button {
    width: 100%;
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

.page-layout {
  min-height: 100vh;
}

.edit-form {
  .ant-modal-content {
    width: 650px;
  }
  &__save-button {
    width: 100%;
  }
  &__save-photo {
    @include blue-button;
  }
}

.ant-message {
  width: auto;
  top: 10px;
  right: 0;
  left: inherit;
}

.ant-input-number-lg,
.ant-input-number-group-wrapper {
  width: 100%;
}
.lang-tab {
  .ant-tabs-nav {
    margin: 0;
  }
}
