.review-detail-item {
  padding: 16px;

  display: flex;
  position: relative;

  .content {
    margin-left: 12px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .fullname {
        margin-right: 13px;
      }

      .edit-button {
        color: #1890ff;
        cursor: pointer;

        position: absolute;
        right: 16px;
      }
    }

    .text {
      color: #262626;
    }
  }
}
