.period-wrapper {
  .period-date {
    .period-date__title {
      margin-top: 16px;
      margin-bottom: 4px;
    }
    .ant-picker {
      width: 100%;
    }
  }
  .ant-form-item {
    margin: 0;
    width: 100%;
  }
}
