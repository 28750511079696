.specific-wrapper {
  h5 {
    padding-top: 24px;
    padding-bottom: 16px;
  }
  .ant-form-item {
    margin: 0;
  }

  .specific-checkbox {
    width: 100%;
  }

  .custom-checkbox {
    width: 100%;
    padding: 10px 13px 10px 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .ant-checkbox + span {
      margin-right: auto;
    }
  }
  .custom-checkbox:hover {
    border: 1px solid #1890ff;
    color: #1890ff;
  }
  .custom-checkbox.active {
    border: 1px solid #1890ff;
    color: #1890ff;
  }
}
