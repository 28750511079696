@mixin blue-button {
  background-color: #ddefff;
  border-color: #ddefff;
  color: #1890ff;
  fill: #1890ff;
  &:hover {
    background-color: #1890ff;
    border-color: #1890ff;
    color: #ddefff;
    fill: #ddefff;
  }
}
