.item-add__wrapper {
  width: 100%;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: 16px 0;
  margin-bottom: 24px;

  .item-add__content {
    padding: 16px 16px 0px 16px;
  }

  .ant-divider-horizontal {
    margin: 16px 0px 0px 0px;
  }

  > .ant-col {
    margin-left: 8px;
  }
}
