.card-collections-psychologists-preview {
  width: 100%;
  padding: 16px;
  border: 1px solid #00b5ce;
  background-color: #fff;

  .card-collections-psychologists-preview-header {
    display: flex;
    justify-content: space-between;

    h5 {
      display: block;
    }

    a {
      display: block;
    }
  }

  .card-collections-psychologists-preview-content {
    h3 {
      color: #116dc2;
      margin: 0;
      margin-bottom: 8px;
    }

    a {
      color: #818181;
    }
  }
}
