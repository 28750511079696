.form-refund {
  &_child {
    > div.ant-row {
      margin-bottom: 5px;
    }
    > div:first-child {
      margin-top: 0;
    }
    > div:last-child {
      margin-bottom: 16px;
    }
  }
}
