.chat {
  &-overview, &-detail {
    background-color: #fff;
  }

  &-detail {
    padding: 15px 30px;
    max-height: 585px;
    overflow: auto;
  }

  &-message {
    text-align: left;
    padding: 15px;
    border-radius: 2px;
    background-color: #f8f8f8;
    margin-bottom: 15px;

    &-text {
      margin-left: auto;
      color: #262626;
      text-align: left;
    }

    &-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &-time, &-name {
        opacity: 0.6;
        font-size: 0.7em;
        color: #8c8c8c;
      }

      &-time {
        padding-right: 5px
      }
    }
  }

  &-user {
    margin-left: 15px;
  }

  &-block {
    display: flex;
    flex-wrap: nowrap;

    &-expert {
      flex-direction: row-reverse;

      .chat-user {
        margin-left: 0;
        margin-right: 15px;
      }

      .chat-message-info {
        flex-direction: row-reverse;

        &-time {
          padding-right: 0;
          padding-left: 5px;
        }
      }
    }
  }
}
