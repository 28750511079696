.limit-amount-wrapper {
  .limit-amount-price {
    .limit-amount__title {
      margin-top: 16px;
      margin-bottom: 4px;
    }
    input {
      width: 100%;
    }
  }
  .ant-form-item {
    margin: 0;
    width: 100%;
  }
}
