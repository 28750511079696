.usage-wrapper {
  .quantity-value {
    margin-top: 16px;
    input {
      margin-top: 2px;
    }
  }
  .ant-form-item {
    margin: 0;
  }
}
