.user-square {

  height: 100%;
  background-color: #ffece1;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  text-align: center;
  span {
    font-weight: normal;
    font-size: 14px;
    color: #818181;
    display: block;
    &:last-child {
      font-size: 20px;
      color: #1890ff;
    }
  }
}

@media screen and (max-width: 1366px) {
  .user-square {
    padding-top: 12px;
    padding-bottom: 12px;

    span {
      font-size: 11px;
      &:last-child {
        font-size: 16px;
      }
    }
  }
}
