#root {
  .ant-typography {
    margin-bottom: 0;
  }

  .ant-layout {
    background: #f0f2f5;
  }

  .ant-layout-sider {
    background: #ffffff;
  }

  //Tab
  .ant-tabs-top {
    > .ant-tabs-nav::before {
      border-bottom-color: #dfdfdf;
    }
  }

  //Table
  .ant-table-thead {
    > tr {
      > th {
        font-weight: 900;
      }
    }
  }

  //Header
  .ant-page-header {
    padding: 0;
    margin-bottom: 20px;
  }

  .ant-avatar {
    min-width: 32px;
    max-width: 32px;
  }
}
