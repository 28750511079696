@import '/src/assets/scss/constant';

.discount-summary {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: $card-padding 0;
  .discount-container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .ant-divider {
    margin: 16px 0;
  }
  > .ant-col {
    margin-left: 8px;
  }
}
