// FIXME: update fonts

@font-face {
  font-family: 'SF Pro Text';
  src: url('//db.onlinewebfonts.com/t/1c45e28f8e86cc89876f003b953cc3e9.eot');
  src: url('//db.onlinewebfonts.com/t/1c45e28f8e86cc89876f003b953cc3e9.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/1c45e28f8e86cc89876f003b953cc3e9.woff2')
      format('woff2'),
    url('//db.onlinewebfonts.com/t/1c45e28f8e86cc89876f003b953cc3e9.woff')
      format('woff'),
    url('//db.onlinewebfonts.com/t/1c45e28f8e86cc89876f003b953cc3e9.ttf')
      format('truetype'),
    url('//db.onlinewebfonts.com/t/1c45e28f8e86cc89876f003b953cc3e9.svg#SF Pro Text')
      format('svg');
}

blockquote,
body,
code,
dd,
div,
dl,
dt,
fieldset,
form,
input,
pre,
td,
textarea,
th {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
  max-height: 100000px;
  font-family: 'SF Pro Text';
}
li {
  list-style-type: none;
}
ul {
  padding-left: 0;
  margin: 0;
}
a {
  text-decoration: none;
  cursor: pointer;
}
textarea,
input {
  outline: none;
  border: none;
}
button {
  outline: none;
  cursor: pointer;
}

.sort-icon {
  fill: #1890ff;
  margin-right: 9px;
}

.select {
  width: 100%;

  display: flex;
  align-items: center;

  .title {
    width: 15%;
  }

  .select {
    width: 85%;

    & > div {
      width: 100%;
    }
  }
}

.vertical-gap {
  margin: 5px 0;
}
